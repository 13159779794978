import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { LoggerConfiguration } from '../../models/app-configuration.model';
import { DngLoggerService } from '../logging/dng-logger.service';
import { InitDataApiService } from '../api/init-data/init-data-api.service';
import { InitDataDto } from '../../models/init-data.model';
import { LoadingState, UIState } from '../../models/state/ui-state.model';

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  initData = new BehaviorSubject<UIState<InitDataDto | null>>({
    value: null,
    loadingState: LoadingState.Unknown,
  });

  constructor(
    private translate: TranslateService,
    private httpBackend: HttpBackend,
    private logger: DngLoggerService,
    private initDataApi: InitDataApiService,
  ) {}

  public async initializeApp() {
    await this.initializeTranslations();
    this.initializeLoggerConfig();
  }

  public initializeLoggerConfig() {
    const config: LoggerConfiguration = {
      disableConsoleLogging: environment.logging.disableConsoleLogging,
      dataDogEnabled: environment.logging.dataDogEnabled,
      level: environment.logging.level,
    };
    this.logger.updateLoggerConfiguration(config);
  }

  public async initializeTranslations() {
    const url: string = environment.translationUrl;
    this.translate.defaultLang = 'en';
    this.translate.currentLoader = new TranslateHttpLoader(
      new HttpClient(this.httpBackend),
      url,
    );
    this.translate.setDefaultLang('en');
    await firstValueFrom(this.translate.use('en'));
  }

  public getInitData() {
    this.initData.next({
      value: null,
      loadingState: LoadingState.Loading,
    });
    this.initDataApi.getInitData().subscribe((data) => {
      this.initData.next({
        value: data,
        loadingState: LoadingState.Loaded,
      });
    });
  }
}
