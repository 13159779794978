/**
 * Describes a state of an object that has to go through a loading process, ie through an api
 */
export interface UIState<T> {
  /**
   * The value of the UIState
   */
  value: T;

  /**
   * The current value of the call and if it is loading or not
   */
  loadingState: LoadingState;

  /**
   * If an error occurs this value will be populated with information about the error
   */
  error?: ErrorState;
}

/**
 * Provides information in regard to the loading state of the api call
 */
export enum LoadingState {
  /**
   * Likely the call has not yet started
   */
  Unknown = 'unknown',
  /**
   * The call is loading
   */
  Loading = 'loading',
  /**
   * The call has loaded successfully
   */
  Loaded = 'loaded',
  /**
   * The call has finished but failed
   */
  Error = 'error',
}

/**
 * Description of the error for a failed call.
 */
export interface ErrorState {
  /**
   * The error message
   */
  errorMessage: string;

  /**
   * An error code if one exists
   */
  errorCode?: number;

  /**
   * Useful to store additional error information
   */
  additionalParams?: ErrorParam[];
}

/**
 * Used to additional error parameters
 */
export interface ErrorParam {
  /**
   * Identifier for the parameter
   */
  key: string;

  /**
   * Parameter value, can be anything
   */
  value: any;
}
