import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { LoggerConfiguration } from '../../models/app-configuration.model';
import { LoggingApiService } from './logging-api.service';
import { LogEvent, LogLevel } from '../../models/log-event.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * This class is meant to purely work as a wrapper/facade for the logging implementation.
 */
export class DngLoggerService {
  dataDogLoggingEnabled = false;

  constructor(
    private readonly logger: NGXLogger,
    private readonly logApi: LoggingApiService,
  ) {}

  logError(message: string, data?: any[]) {
    this.logInternal(NgxLoggerLevel.ERROR, message, data);
  }

  logDebug(message: string, data?: any[]) {
    this.logInternal(NgxLoggerLevel.DEBUG, message, data);
  }

  logInfo(message: string, data?: any[]) {
    this.logInternal(NgxLoggerLevel.INFO, message, data);
  }

  logWarn(message: string, data?: any[]) {
    this.logInternal(NgxLoggerLevel.WARN, message, data);
  }

  updateLoggerConfiguration(config: LoggerConfiguration) {
    this.logger.updateConfig({
      level: config?.level ?? NgxLoggerLevel.INFO,
      disableConsoleLogging: config.disableConsoleLogging,
    });
    this.dataDogLoggingEnabled = config?.dataDogEnabled ?? false;
  }

  private logInternal(level: NgxLoggerLevel, message: string, data?: any[]) {
    const logEvent: LogEvent = {
      logLevel: this.getEventLevel(level),
      message,
      properties: data,
      environment: environment.environmentName,
      serviceName: environment.serviceName,
    };
    switch (level) {
      case NgxLoggerLevel.DEBUG:
        this.logger.debug(message, data);
        break;
      case NgxLoggerLevel.ERROR:
        this.logger.error(message, data);
        break;
      case NgxLoggerLevel.INFO:
        this.logger.info(message, data);
        break;
      case NgxLoggerLevel.OFF:
        break;
      case NgxLoggerLevel.WARN:
        this.logger.warn(message, data);
    }
    if (this.dataDogLoggingEnabled) {
      this.logApi.logEvent(logEvent).subscribe();
    }
  }

  private getEventLevel(ngxLevel: NgxLoggerLevel): LogLevel {
    switch (ngxLevel) {
      case NgxLoggerLevel.TRACE:
        return LogLevel.Verbose;
      case NgxLoggerLevel.DEBUG:
        return LogLevel.Debug;
      case NgxLoggerLevel.INFO:
        return LogLevel.Information;
      case NgxLoggerLevel.LOG:
        return LogLevel.Information;
      case NgxLoggerLevel.WARN:
        return LogLevel.Warning;
      case NgxLoggerLevel.ERROR:
        return LogLevel.Error;
      case NgxLoggerLevel.FATAL:
        return LogLevel.Fatal;
      default:
        return LogLevel.Information;
    }
  }
}
