import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  apiHost: 'https://develop.api.myvacation.house',
  translationUrl: 'https://diangular.gitlab.io/translations/',
  auth0: {
    clientId: '8CSwzJSdAqcpzCsdcVbMyipUaCkQMu9m',
    domain: 'dev-p44ms8yco85ja5po.us.auth0.com',
    audience: 'https://diangular.com/control-server',
    redirectUrl: '',
  },
  logging: {
    level: NgxLoggerLevel.DEBUG,
    dataDogEnabled: true,

    disableConsoleLogging: false,
  },
  environmentName: 'development',
  serviceName: 'mvh-client',
};
