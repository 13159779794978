import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { HttpClient } from '@angular/common/http';
import { InitDataDto } from '../../../models/init-data.model';

@Injectable({
  providedIn: 'root',
})
export class InitDataApiService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
    this.apiPrefix = '/initData';
  }

  public getInitData() {
    return this.http.get<InitDataDto>(this.baseRoute);
  }
}
