import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { V1RoutePrefix } from '../../models/constants/api.constants';

@Injectable({
  providedIn: 'root',
})
export class ApiBaseService {
  apiPrefix = '/';

  constructor(protected http: HttpClient) {}

  get baseRoute() {
    return `${environment.apiHost}${V1RoutePrefix}${this.apiPrefix}`;
  }
}
