import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiBaseService } from '../api/api-base.service';
import { LogEvent } from '../../models/log-event.model';

@Injectable({
  providedIn: 'root',
})
export class LoggingApiService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
    this.apiPrefix = '/log';
  }

  public logEvent(logEvent: LogEvent) {
    return this.http.post<LogEvent>(this.baseRoute, logEvent);
  }
}
